body {
  margin: 0;
  font-family: 'Balthazar', serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
h1, h2, h3, h4, h5, h6{
  font-family: 'Balthazar', serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

p{
  font-family: 'Balthazar', serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  margin: 0 0 14px;
}

html{
  overflow-y: scroll;
  height: 100vh; 
  scroll-snap-type: y mandatory;
  scrollbar-width: none;
  -ms-overflow-style: none;
  scroll-behavior: smooth;
  -webkit-scrollbar {
    display: none; 
  }

}
@media all and (max-width: 1049px){
  html{
    scroll-snap-type: none;
  }
}

@keyframes hoverUpAndDown {
  0%, 100% {
    transform: translateY(0);
  }
  50% {
    transform: translateY(-50px);
  }
}

.hover-up-down {
  animation: hoverUpAndDown 3s ease-in-out infinite; /* Adjust the duration as needed */
}


.scrolling-text-container {
  width: 100%;
  padding: 10px 0;
  background: #310423;
  overflow: hidden;
  white-space: nowrap;
  position: relative;
}

.scrolling-text {
  display: inline-block;
  font-family: 'Comic Neue', sans-serif;
  font-weight: 600;
  font-size: 28px;
  color: #e381c5;
  animation: scroll-text 400s linear infinite;
}
.scrolling-text span{
  margin-left: 30px;
}

@keyframes scroll-text {
  0% {
    transform: translateX(100%);
  }
  100% {
    transform: translateX(-100%);
  }
}

@keyframes scroll-text {
  from {
    transform: translateX(0);
  }
  to {
    transform: translateX(-100%);
  }
}

/* shake on hover */
@keyframes shake {
  0%, 100% {
    transform: translateX(0);
  }
  10%, 30%, 50%, 70%, 90% {
    transform: translateX(-10px);
  }
  20%, 40%, 60%, 80% {
    transform: translateX(10px);
  }
}

.shake-on-hover:hover {
  animation: shake 0.8s; /* Adjust duration as needed */
  animation-timing-function: ease-in-out;
}



.custom-modal-image-style{
  border-radius: 20px;
}

/* Pulsing mint status dot */
.pulsing-dot {
  width: 10px;
  height: 10px;
  border-radius: 50%;
  animation: pulse 2500ms infinite ease-out;
}

.pulsing-dot.red {
  background-color: red;
}

.pulsing-dot.orange {
  background-color: #ffb222;
}

.pulsing-dot.green {
  background-color: #08e408;
}

@keyframes pulse {
  0% {
    transform: scale(0.90);
    opacity: 0.6;
  }
  
  50% {
    transform: scale(1);
    opacity: 1;
  }
  
  100% {
    transform: scale(0.90);
    opacity: 0.6;
  }
}